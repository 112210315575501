const mandant: InsuranceConfigs = {
  levelnine: {
    allowRattings: true,
    disableDataProtectionBox: true,
    sendAttachments: {
      agent: true,
      client: true,
    },
    allowAgentInfoBox: true,
    maxPerson: 0,
    minPrice: null,
    minAge: null,
    allowedAgentNumbers: [],
    remarks: null,
    infoBoxOnStartpage: 'LevelnineInfoBoxComponent',
    dateComponentOnStartPage: null, // SDKBeginDateSelection
    logo: {
      size: '20',
      position: 'left',
    },
    footer: {
      companyText: '© 2024 CPIT Comparit GmbH',
      imprint: 'https://www.levelnine.de/impressum',
      dataprotection: 'https://www.levelnine.de/datenschutz',
      dataprotectionC: 'https://www.levelnine.de/datenschutz',
      dataprotText: '"Informationen zur Verwendung Ihrer Daten"',
    },
    agentNumberValidation: {
      validation: null,
      validation2: null,
      errorMessage: null,
      errorMessage2: null,
    },
    birthdateValidation: false,
    customTemplate: {
      client: 'insurances-online-customer-confirmation-DEFAULT',
      agent: 'insurances-online-agent-confirmation-LEVELNINE_TEST',
    },
    exclusiveSelectionPerCategory: false,
    useTinyURL: true,
    employeeInsurance: false,
    blacklistedTariffs: [],
  },
  hallesche: {
    allowedAgentNumbers: [],
    disableDataProtectionBox: true,
    allowRattings: true,
    sendAttachments: {
      agent: false,
      client: false,
    },
    allowAgentInfoBox: true,
    maxPerson: 0,
    minPrice: null,
    minAge: null,
    remarks: null,
    infoBoxOnStartpage: null,
    dateComponentOnStartPage: null,
    logo: {
      size: '50',
      position: 'center',
    },
    footer: {
      companyText: '© 2024 Hallesche - ALH Gruppe',
      imprint: 'https://www.hallesche.de/impressum',
      dataprotection: 'https://www.hallesche.de/datenschutz',
      dataprotectionC: 'https://www.hallesche.de/datenschutz',
      dataprotText: 'Datenschutzerklärung',
    },
    agentNumberValidation: {
      validation: null,
      validation2: null,
      errorMessage: null,
      errorMessage2: null,
    },
    birthdateValidation: true,
    exclusiveSelectionPerCategory: false,
    useTinyURL: true,
    employeeInsurance: false,
    blacklistedTariffs: [
      'HALAE.1#',
      'HALOPTIfree#',
      'HALAE.2#',
      'HALAE.3#',
      'HALJOKER.#',
      'HALOK.#',
      'HALOPTIfree#',
      'HALplus.U#',
      'HALCSAW 1#',
      'HALCSAW 2#',
      'HALJOKER.#',
      'HALAE.1#dentZB.100#dentZE.90#',
      'HALAE.1#dentZE.90#',
      'HALBISS.80#dentPRO.80#',
      'HALAE.1#BISS.80#dentPRO.80#dentZB.100#',
      'HALAE.1#',
      'HALdentPRO.80#dentZB.100#',
      'HALAE.2#BISS.80#',
      'HALAE.1#dentZB.100#',
      'HALAE.2#dentZB.100#dentZE.90#',
      'HALdentZB.100#plus.Z#',
      'HALAE.2#dentPRO.80#dentZE.100#',
      'HALAE.1#dentPRO.80#dentZB.100#dentZE.100#',
      'HALAE.2#BISS.80#dentZB.100#',
      'HALAE.3#BISS.80#dentPRO.80#dentZB.100#',
      'HALAE.1#dentZB.100#dentZE.100#',
      'HALAE.3#dentZE.100#',
      'HALAE.2#dentZE.100#',
      'HALAE.3#BISS.80#dentZB.100#',
      'HALOPTIfree#',
      'HALAE.2#dentZB.100#',
      'HALAE.3#BISS.80#',
      'HALAE.3#dentZB.100#',
      'HALAE.3#dentPRO.80#',
      'HALdentPRO.80#dentZB.100#dentZE.100#',
      'HALAE.2#dentPRO.80#',
      'HALdentPRO.80#dentZB.100#dentZE.90#',
      'HALAE.1#dentPRO.80#dentZB.100#',
      'HALAE.1#dentPRO.80#dentZE.100#',
      'HALAE.3#dentPRO.80#dentZE.90#',
      'HALdentPRO.80#plus.Z#',
      'HALBISS.80#dentZB.100#',
      'HALAE.3#dentPRO.80#dentZB.100#dentZE.90#',
      'HALAE.2#dentZB.100#dentZE.100#',
      'HALAE.1#dentPRO.80#',
      'HALAE.3#BISS.80#dentPRO.80#',
      'HALAE.2#dentPRO.80#dentZE.90#',
      'HALAE.2#dentPRO.80#dentZB.100#dentZE.90#',
      'HALAE.1#dentPRO.80#dentZB.100#dentZE.90#',
      'HALAE.2#BISS.80#dentPRO.80#',
      'HALAE.1#dentPRO.80#dentZE.90#',
      'HALdentPRO.80#dentZB.100#plus.Z#',
      'HALAE.2#dentPRO.80#dentZB.100#',
      'HALAE.1#dentZE.100#',
      'HALAE.2#BISS.80#dentPRO.80#dentZB.100#',
      'HALAE.3#dentZB.100#dentZE.100#',
      'HALAE.3#dentZB.100#dentZE.90#',
      'HALAE.3#dentPRO.80#dentZB.100#dentZE.100#',
      'HALAE.1#BISS.80#dentPRO.80#',
      'HALBISS.80#',
      'HALAE.2#dentZE.90#',
      'HALAE.2#',
      'HALBISS.80#dentPRO.80#dentZB.100#',
      'HALAE.3#',
      'HALJOKER.#',
      'HALAE.3#dentZE.90#',
      'HALAE.1#BISS.80#',
      'HALAE.1#BISS.80#dentZB.100#',
      'HALAE.3#dentPRO.80#dentZB.100#',
      'HALAE.2#dentPRO.80#dentZB.100#dentZE.100#',
      'HALAE.3#dentPRO.80#dentZE.100#',
    ],
  },
  sdk: {
    allowRattings: false,
    disableDataProtectionBox: false,
    sendAttachments: {
      agent: false,
      client: false,
    },
    allowAgentInfoBox: true,
    maxPerson: 1,
    minPrice: null,
    minAge: 18,
    remarks: null,
    infoBoxOnStartpage: null,
    dateComponentOnStartPage: 'SDKBeginDateSelection',
    logo: {
      size: '20',
      position: 'left',
    },
    footer: {
      companyText: '© 2024 Süddeutsche Krankenversicherung a. G.',
      imprint: 'https://www.sdk.de/impressum/',
      dataprotection: 'https://www.sdk.de/datenschutz/',
      dataprotectionC:
        'https://www.sdk.de/downloads/Datenschutz-Compliance/0.024e-Informationen-zur-Verwendung-Ihrer-Daten.pdf',
      dataprotText: '"Informationen zur Verwendung Ihrer Daten"',
    },
    allowedAgentNumbers: [],
    agentNumberValidation: {
      validation: null,
      validation2: null,
      errorMessage: null,
      errorMessage2: null,
    },
    birthdateValidation: true,
    customTemplate: {
      client: 'insurances-online-customer-confirmation-SDK',
      agent: 'insurances-online-agent-confirmation-SDK',
    },
    exclusiveSelectionPerCategory: true,
    useTinyURL: false,
    employeeInsurance: true,
    blacklistedTariffs: [
      'SDKAP5O#',
      'SDKAP7O#',
      'SDKAP9O#',
      'SDKAP1O#',
      'SDKAB1F#',
      'SDKAB2F#',
      'SDKAB3F#',
      'SDKAB1O#',
      'SDKAB2O#',
      'SDKAB3O#',
      'SDKAG80#',
      'SDKAGF#AGZF#',
      'SDKAGF#',
      'SDKAG80#AGZ#',
      'SDKZB1O#',
      'SDKZB2O#',
      'SDKZB3O#',
      'SDKZP5O#',
      'SDKZP7O#',
      'SDKZP9O#',
      'SDKZP1O#',
      'SDKZB1F#',
      'SDKZB2F#',
      'SDKZB3F#',
      'SDKSP1O#',
      'SDKSP2O#',
      'SDKSPUO#',
      'SDKSG1O#',
      'SDKSG2O#',
    ],
    defaultContactData: {
      firstName: 'SDK',
      lastName: 'Vertriebsservice',
      company: '',
      street: 'Raiffeisenplatz',
      houseNumber: '11',
      zipcode: '70736',
      city: 'Fellbach',
      phone: '0711-7372-7140',
      mobile: '',
      email: 'Vermittlerservice@sdk.de',
    },
  },
  vmk: {
    allowRattings: true,
    disableDataProtectionBox: true,
    sendAttachments: {
      agent: true,
      client: true,
    },
    allowAgentInfoBox: true,
    allowedAgentNumbers: [],
    maxPerson: 0,
    minPrice: 5.0,
    minAge: null,
    remarks: null,
    infoBoxOnStartpage: 'VMKInfoBoxComponent',
    dateComponentOnStartPage: null,
    logo: {
      size: '0',
      position: 'center',
    },
    footer: {
      companyText: '© 2024 Versicherungskammer Maklermanagement Kranken GmbH',
      imprint:
        'https://www.versicherungskammer-makler.de/content/ueber-uns/impressum/',
      dataprotection:
        'https://www.versicherungskammer-makler.de/content/ueber-uns/datenschutz/',
      dataprotectionC:
        'https://www.versicherungskammer-makler.de/content/ueber-uns/datenschutz/',
      dataprotText: '"Informationen zur Verwendung Ihrer Daten"',
    },
    agentNumberValidation: {
      validation: null,
      validation2: null,
      errorMessage: null,
      errorMessage2: null,
    },
    birthdateValidation: false,
    exclusiveSelectionPerCategory: false,
    useTinyURL: true,
    employeeInsurance: false,
    blacklistedTariffs: [],
  },
};

export default mandant;
